<template>
  <!-- i had to repeat codes which is against the number 1 rule, dry -->
  <v-container class="justify-center px-0 pt-0">
    <div class="
        mx-10 mx-sm-12 mx-md-14
        px-md-0
        mb-10
        pt-md-8
        d-flex
        justify-sm-space-between
      " v-if="$vuetify.breakpoint.mdAndUp">
      <h3 class="text-bold primary--text" style="
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
        ">
        Forms
      </h3>
      <v-spacer></v-spacer>
      <create-form-modal ref="formDialog" />
    </div>

    <template>
      <div
        class="d-flex justify-center align-center"
        style="height: 60vh; width: 100%"
        v-if="isLoadingEntries"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-container class="pt-8 px-0">
        <div class="d-flex align-center px-10">
          <span class="d-inline-block font-weight-bold text-capitalize my-auto px-6" style="color: var(--v-primary-base)">
            {{ formName }}
          </span>
        </div>
        <v-card flat elevation="6" width="93%" min-height="674" class="mx-auto mb-md-10 mt-md-2">
          <v-card width="100%" height="58px" flat class="d-flex align-center mx-sm-0 py-0 py-md-2" color="#f4f4f4">
            <v-btn @click="$router.go(-1)" text rounded large class="mx-2">
              <v-icon left>mdi-chevron-left</v-icon> Back
            </v-btn>

            <v-spacer></v-spacer>

            <div style="width = 10px">
              <v-autocomplete class="px-8" dense hide-details="true" v-model="selectedColumns" :items="headers" multiple
                return-object>
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index < 2" class="mb-2">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 2" class="grey--text caption">(+{{ selectedColumns.length - 2 }} others)</span>
                </template>
              </v-autocomplete>
            </div>
            <download-csv class="btn btn-default" :data="dataEntries">
              <v-hover v-slot="{ hover }">
                <v-btn outlined @click="alertCSVDownload" color="primary" class="export-btn mr-9 hover-btn" :style="{
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }" elevation="2"><span class="material-icons pr-1"> import_export </span><span class="primary--text"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 11px;
                      line-height: 12px;
                      text-align: center;
                      letter-spacing: 0.636364px;
                    ">Export</span>
                </v-btn>
              </v-hover>
            </download-csv>
          </v-card>
          <div class="ma-0 pa-0">

            <!-- <pre>{{ displayedItems }}</pre> -->
            <!-- <template>
              <v-data-table height="100%" :headers="selectedColumns" :items="transformedItems" class="elevation-0"
                hide-default-footer @click:row="handleRowClick">

              </v-data-table>
            </template> -->

            <template>
              <v-data-table height="100%" :headers="selectedColumns" :items="displayedItems" class="elevation-0"
                hide-default-footer @click:row="handleRowClick">
                <!-- <template v-slot:item="{ item }">
                  <tr>
                    <td v-for="(value, key) in item" :key="key">
                      <span v-if="value === 'click to see more'" style="color: red;">
                        {{ value }}
                      </span>
                      <span v-else>
                        {{ value }}
                      </span>
                    </td>
                  </tr>
                </template> -->
              </v-data-table>
            </template>



            <!-- pagination component -->
            <div class="pagination__container px-8" v-if="dataEntries && dataEntries.length > 0">
              <BasePagination :current-page="currentPage" :visible-pages="visiblePageValue" :total-pages="totalPages"
                @page-changed="handlePageChange" />
            </div>
          </div>
        </v-card>
      </v-container>
    </template>

    <div>
      <v-navigation-drawer :hide-overlay="true" width="390px" v-model="entriesDrawer" right temporary app
        class="navigator">
        <SingleEntriesDetails :entryId="singleEntryId" />
      </v-navigation-drawer>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import createFormModal from "../../includes/overlays/createForm/createFormModal.vue";

import SingleEntriesDetails from "@/pages/forms/singleEntriesDetails.vue";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination";
export default {
  components: {
    createFormModal,
    BasePagination,
    SingleEntriesDetails,
  },
  name: "entries",

  data() {
    return {
      isClicked: true,
      search: "",
      formName: "",
      dataEntries: [],
      fullEntries: [],
      hypn_id: null,
      headers: [],
      // allEntries: null,
      selectedHeaders: [],
      selectedColumns: [],
      itemsPerPage: 10,
      page: 1,
      entriesDrawer: false,
      singleEntryId: null,
      isLoadingEntries: true,
    };
  },
  mixins: [pagination],
  methods: {
    ...mapActions({
      showToast: "ui/showToast",
      formEntries: "formBuilder/getFormEntries",
    }),
    showModal() {
      this.$refs.formDialog.openDialog();
    },
    toggleSearch() {
      this.isClicked = false;
    },
    searchDataTable(e) {
      this.$refs.dataTable.setSearchText(e);
    },
    addColumns(event) {
      console.log(event);
      //this.selectedColumns = event;
      console.log(JSON.stringify(this.selectedColumns, null, 2));
    },
    async fetchFormsById() {
      let response = await this.$store.dispatch(
        "formBuilder/getSingleForm",
        this.$route.params.id
      );
      // console.log(JSON.stringify(response.data.data.form_fields, null, 2));
      // console.log(JSON.stringify(response.data.data.hypn_id, null, 2));

      this.hypn_id = response.data.data.hypn_id;
    },
    async fetchEntries() {
      this.isLoadingEntries = true;
      try {
        let response = await this.$store.dispatch(
          "formBuilder/getFormEntries",
          this.hypn_id
        );

        this.formName = response.data.form_title;
        const allEntries = response.data.data;

        console.log(JSON.stringify(allEntries, null, 2));
        // sort array according to the date created
        const sortedFormEntryArray = allEntries.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        this.fullEntries = allEntries;
        // console.log(JSON.stringify(sortedFormEntryArray, null, 2));

        //map over the sortedFormEntryArray and get a new array that contains just the form_entry objs
        const formEntryArray = sortedFormEntryArray.map(
          (entry) => entry.form_entry
        );

        this.dataEntries = formEntryArray;

        //get the first object in the array and get the key each value....

        //get a {text:'',value:''} to use as the headers for the datatable

        const newNameLabel = Object.keys(this.dataEntries[0]).map((key) => {
          return {
            text: key
              .replaceAll("_", " ")
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" "),
            value: key,
          };
        });

        //return the textValue array as the header array
        this.headers = newNameLabel;
        this.selectedColumns = [
          {
            text: "#",
            value: "index",
          },
          ...this.headers.slice(0, 5),
        ];
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.isLoadingEntries = false;
      }
    },

    //populate the header array from localstorage
    populateHeaders() {
      let headers = JSON.parse(localStorage.getItem("headers"));
      if (!headers) {
        this.selectedHeaders = this.headers;
      } else {
        this.selectedHeaders = headers;
      }
    },

    //download entries as cvs file
    alertCSVDownload() {
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 3000,
      });
    },

    //handle entries info when a row is clicked
    handleRowClick(item) {
      this.singleEntryId = this.fullEntries[item.index - 1].id,
        // console.log("Row clicked:", JSON.stringify(this.singleEntryId, null, 2));
        this.entriesDrawer = true;
    },


  },
  computed: {

    makePayment: {
      get() {
        return this.$store.state.formBuilder.makePayment;
      },
      set(value) {
        this.$store.commit("formBuilder/SET_MAKEPAYMENT", value);
      },
    },
    //Done to get the ordered headers
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },

    ...mapGetters({
      singleForm: "formBuilder/getSingleForm",
    }),

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.dataEntries.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      let arraySubset;

      if (this.dataEntries && this.dataEntries.length > this.itemsPerPage) {
        arraySubset = this.dataEntries.slice(startIndex, endIndex);
      } else {
        arraySubset = this.dataEntries.slice();
      }

      return arraySubset.map((item, index) => {
        return {
          index: index + 1 + (this.currentPage - 1) * this.itemsPerPage,
          ...Object.fromEntries(
            Object.entries(item).map(([key, value]) => [
              key,
              Array.isArray(value) ? "Tap to view" : (typeof value === 'object' && value !== null ? "Tap to view" : value)

            ])
          )
        };
      });
    },
    totalPages() {
      return Math.ceil(this.dataEntries.length / this.itemsPerPage);
    },

    // transformedItems() {
    //   return this.displayedItems.map(item => {
    //     for (const key in item) {
    //       if (Array.isArray(item[key])) {
    //         item[key] = "click to see more";
    //       }
    //     }
    //     return item;
    //   });
    // }
  },

  async mounted() {
    await this.fetchFormsById();
    await this.fetchEntries();

    this.selectedHeaders = this.headers;
    console.log(this.selectedHeaders);
  },
};
</script>

<style>
.active {
  color: var(--v-primary-base);
}

tbody tr:nth-of-type(odd) {
  background-color: #f8f9fc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

table td,
table th {
  height: 40px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: none;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0 36px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.pageTitle {
  margin-top: 25px;
  margin-bottom: 7px;
  margin-left: 42px;
  padding-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.45px;
  color: var(--v-primary-base);
}

.pageDespt {
  margin-bottom: 27px;
  margin-left: 42px;
  padding-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #7f919b;
}

.v-data-table-header th {
  white-space: nowrap;
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
